<template>
     <BT-Blade-Items
        addBladeName="purchase-order"
        bladeName="purchase-orders"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        flexColumn
        :getNewBladeData="getNewBladeData"
        :getSelectBladeData="getSelectBladeData"
        :getParams="item => { return { includeDetails: false, supplierIDs: item.data == null ? null : item.data.supplierID } }"
        :headers="[
            { text: 'PO#', value: 'purchaseOrderNumber', subtitle: 2, prefix: 'PO#', searchable: true },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toShortDate', subtitle: '1', prefix: 'Due: ' },
            { text: 'Supplier', value: 'seller.companyName', title: 1, searchable: true },
            { text: 'Destination', value: 'location', textFilter: 'toLocationLine', subtitle: 0 },
            { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency' },
            { text: 'Status', value: 'status', display: true, subtitle: 3 }]"
        navigation="purchase-orders"
        :searchProps="['customerOrderNumber']"
        :onCanDelete="(item) => { return item.isInactive || item.seller.isManagedByAnotherAccount }"
        :onCanEdit="(item) => { return !item.isInactive }"
        title="Purchase Orders"
        useServerPagination>
        <template v-slot:settings>
            <BT-Entity
                v-if="$canView('customer-settings')"
                ignoreID
                inline
                navigation="customer-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-divider />
                    <v-subheader>Settings</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Default Destination</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <BT-Menu-Select
                                icon="mdi-map-marker"
                                itemText="locationName"
                                itemValue="id"
                                navigation="locations"
                                v-model="item.defaultLocationID"
                                @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Purchase Order Numbering</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <BT-Toggle-Trigger-Icon v-model="item.purchaseOrderNumberTrigger" @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="item.purchaseOrderNumberTrigger != 'Manual'">
                        <v-list-item-content>
                            <v-list-item-subtitle>Last Purchase Order Number</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <span>#{{ item.lastPurchaseOrderNumber }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model.number="item.lastPurchaseOrderNumber"
                                        @change="save"
                                        prepend-icon="#"
                                        singleLine
                                        hideDetails
                                        type="number" />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Email Purchase Order Notifications To</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <v-btn small icon :title="item.emailAddressesAsCustomer">
                                    <v-icon small :class="item.emailAddressesAsCustomer == null || item.emailAddressesAsCustomer.length == 0 ? '' : 'success--text'">mdi-email</v-icon>
                                </v-btn>
                                <template v-slot:input>
                                    <BT-Tags 
                                        v-model="item.emailAddressesAsCustomer"
                                        @change="save" />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
            <BT-Entity
                v-if="$canView('courier-settings')"
                ignoreID
                inline
                navigation="courier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Organize Courier</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.courierSelectionGuideAsCustomer)"
                                    v-model="item.courierSelectionTriggerAsCustomer"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon 
                                    v-model="item.courierSelectionGuideAsCustomer"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
        </template>
        <template v-slot:status="{ item }">
            <span v-if="item.isInactive">Deleted</span>
            <span v-else-if="item.isConfirmed == null">Awaiting Response</span>
            <span v-else-if="item.isConfirmed == true">Confirmed</span>
            <span v-else>Rejected</span>
        </template>
     </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Purchase-Orders',
    components: {
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    props: {
        bladesData: null
    },
    methods: {
        getNewBladeData(bladeData) {
            return {
                agreementID: bladeData.data.agreementID
            }
        },
        getSelectBladeData(bladeData, item) {
            return {
                agreementID: item.supplyAgreementID
            }
        },
    }
}
</script>